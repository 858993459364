import { ArrowLeftIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { Oasis } from '@oasis/sdk';
import { FileUtils } from '@oasis/utils';
import { useEffect, useMemo } from 'react';
import { Link, useLocation, useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { HackathonViewer } from '~/features/files/components/hackathon-viewer';
import { Button } from '~/shared/components/base/button';
import { OasisErrorBoundary } from '~/shared/components/base/oasis-error-boundary';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Queries } from '~/shared/hooks/queries';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { LargeModelViewer } from '../../components/large-model-viewer';
import { HackathonHeatmapViewer } from '../../components/hackathon-heatmap-viewer';

// In order to show the correct "Back to X" button we set state on `location`
const stateSchema = z.object({
  from: z.union([z.literal('Workshops'), z.literal('Files'), z.literal('Issues')]),
  backUrl: z.string(),
});

export default function FilesShowPage() {
  const navigate = useNavigate();
  const loc = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams() as { projectId: string; documentId: string };
  const $env = Oasis.Env.useStore();
  const fileMatch = useMatch('/projects/:projectId/files/:fileId');
  const HACKATHON = useFeatureFlags('hackathon-wxr-platform-team');
  const HACK_HEATMAP = useFeatureFlags('hack-heatmap');

  const { data, isLoading } = Queries.Files.useFindDocumentById({
    projectId: params.projectId,
    documentId: params.documentId,
  });

  useEffect(() => {
    if (!searchParams.get('folder') && data?.folderId) {
      searchParams.set('folder', data.folderId);
      navigate({ pathname: loc.pathname, search: searchParams.toString() }, { replace: true, state: loc.state });
    }
  }, [data, loc, navigate, searchParams]);

  useEffect(
    () => {
      if (Oasis.Env.store.isVr) {
        Oasis.Segment.track('LMV Viewed in VR', params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const backButton = useMemo(() => {
    const state = stateSchema.safeParse(loc.state);

    if (state.success) {
      return state.data;
    }

    return {
      from: 'Workshops',
      backUrl: `/projects/${params.projectId}?${searchParams.toString()}`,
    };
  }, [loc, params.projectId, searchParams]);

  const filename = fileMatch && data?.filename;
  const truncatedFilename = filename && FileUtils.truncateFilenameFromMiddle(filename, 40, 8);

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <ProgressRing size="large" />
        </div>
      </div>
    );
  }

  function showAllObjects() {
    // @TODO - NOP_VIEWER could be not defined if LMV is not fully loaded, we should wait for it to be loaded
    // using React hooks for cleaner solution, then hide/show it accordingly.
    try {
      (globalThis as any).NOP_VIEWER.showAll();
    } catch (error) {
      Oasis.Logger.error({ msg: 'NOP_VIEWER.showAll() failed for LMV.', error });
    }
  }

  if (data) {
    return (
      <div className="flex flex-col flex-1">
        {$env.isVr && (
          <div className="flex items-center min-h-12 pl-3">
            <Tooltip content={filename} placement="bottom-start" className="break-all max-w-[14rem] mt-2">
              <div className="flex items-center group w-full py-1">
                <p className="text-charcoal-900 text-heading-4">{truncatedFilename}</p>
              </div>
            </Tooltip>

            <div className="ml-auto pr-4">
              <Button variant="secondary" onClick={showAllObjects}>
                Show All Objects
              </Button>
            </div>
          </div>
        )}

        {!$env.isVr && (
          <section className="flex items-center py-2">
            <div className="w-14 flex items-center justify-center">
              <Button variant="tertiary" asChild>
                <Link to={backButton.backUrl} className="!px-1">
                  <ArrowLeftIcon />
                </Link>
              </Button>
            </div>

            {filename && (
              <>
                <div className="w-[1px] h-8 bg-charcoal-200 mr-4" />
                <Tooltip content={filename} placement="bottom-start" className="break-all max-w-[14rem] mt-2">
                  <div className="flex items-center group w-full py-1">
                    <p className="text-charcoal-900 text-heading-4">{truncatedFilename}</p>
                  </div>
                </Tooltip>
              </>
            )}

            {params.projectId && data && (
              <div className="flex space-x-2 ml-auto mr-4">
                <Button variant="secondary" onClick={showAllObjects}>
                  Show All Objects
                </Button>
              </div>
            )}
          </section>
        )}

        <OasisErrorBoundary additionalData={{ ...data, ...params }}>
          {HACKATHON ? (
            <HackathonViewer versionId={data.latestVersionId} />
          ) : HACK_HEATMAP ? (
            <HackathonHeatmapViewer versionId={data.latestVersionId} />
          ) : (
            <LargeModelViewer versionId={data.latestVersionId} />
          )}
        </OasisErrorBoundary>
      </div>
    );
  }

  return <OasisErrorState code="NOT_FOUND" />;
}
