import { ViewpointProvider as OldViewpointProvider } from '@adsk/acc-issues/es/exports/ViewpointMarkups';
import {
  Hooks,
  LevelsPanelBridge,
  ModelBrowserBridge,
  SavedViewsBridge,
  SheetsPanelBridge,
  ViewpointsBridge,
} from '@adsk/acc-viewer-ui/es';
import { getProjectsConfiguration } from '@adsk/acc-viewer-ui/es/api/projectAggregateExtensionApi/utils/projectsConfiguration';
import { ActiveFilterContentProvider, FilterStateProvider } from '@adsk/acc-viewer-ui/es/bridges/FilterBridge';
import { ViewerModelPropertyFilteringFacadeProvider } from '@adsk/acc-viewer-ui/es/facades/FilterFacade';
import { ViewerContainerProvider } from '@adsk/acc-viewer-ui/es/providers/viewerContainerProvider/ViewerContainerProvider';
import { getApigeeUrl } from '@adsk/acs-sc-web-platform-api';
import { AlloyProvider } from '@adsk/alloy-provider';
import { BookmarkIcon, LevelsIcon, ModelBrowserIcon, SheetsIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { ViewpointProvider as NewViewpointProvider, useViewpointMarkups } from '@adsk/viewpoint-markups';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { useEffect, useMemo, useRef, useState, type ComponentProps } from 'react';
import { useProxy } from 'valtio/utils';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { useProjectContext } from '~/shared/contexts/project-context';
import { CollaborationExtensionState, useCollaborationExtension } from '~/shared/hooks/use-collaboration-extension';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { FluidErrorState } from './components/fluid-error-state';
import type { Viewable } from './types';

interface Props {
  versionId: string;
}

type OasisViewer = Autodesk.Viewing.AggregatedView & { viewer: Autodesk.Viewing.GuiViewer3D };

/**
 * Some hack thing that acc-viewer does
 * https://git.autodesk.com/plangrid/acc-viewer/blob/242cd7918086e143d3f506877a48902c9d3cf9c9/packages/viewer/app/Viewer/Viewer.tsx#L186-L202
 */
function ViewpointProvider({ viewer, children }: ComponentProps<typeof NewViewpointProvider>) {
  return (
    <NewViewpointProvider viewer={viewer}>
      <OldViewpointProvider viewer={viewer}>{children}</OldViewpointProvider>
    </NewViewpointProvider>
  );
}

export function HackathonViewer({ versionId }: Props) {
  const $env = Oasis.Env.useStore();
  const $collab = useProxy(CollaborationExtensionState);
  const [enableDocumentBrowser, throttleLMVFlag, noGeometryLoadingFlag, isCameraSync] = useFeatureFlags([
    '230711-3102-enable-lmv-document-browser',
    '230926-3543-throttle-lmv',
    '231002-4151-no-geometry-loading-in-lmv',
    '241015-7221-unreal-lmv-cameras-sync',
  ]);
  Hooks.useMainBubble();
  const noGeometryLoading = false;
  const throttleLMV = $env.isVr;

  const ref = useRef<HTMLDivElement>(null);
  const oasisViewer = useRef<OasisViewer>();
  const view = oasisViewer.current;
  const viewer = oasisViewer.current?.viewer;
  const listenerAdded = useRef(false);

  const resourcesLoaded = _useLoadResources(!!throttleLMV, !!noGeometryLoading);
  const [modelLoaded, updateModelLoaded] = useState(false);
  const [activeTool, setActiveTool] = useState<string | null>(null);
  const [bubbleNode, setBubbleNode] = useState<Autodesk.Viewing.BubbleNode | null>(null);
  const [viewable, setViewable] = useState<Viewable | null>(null);
  const [hasViewPoints, setHasViewpoints] = useState(false);

  const levelsExt = useRef<Autodesk.AEC.LevelsExtension | null>(null);
  const { isViewpointModeEnabled } = useViewpointMarkups(viewer);

  const { connectionStatus } = useCollaborationExtension({
    viewer,
    versionId,
    modelLoaded,
  });
  const { projectId } = useProjectContext();

  useEffect(() => {
    async function loadExtension(currentViewer: Autodesk.Viewing.GuiViewer3D) {
      if (enableDocumentBrowser) {
        const existing = await currentViewer.getExtension('Autodesk.DocumentBrowser');
        if (existing) {
          // we cannot reactivate DocumentBrowser if it had already been loaded to we need to reload the page
          // window.location.reload();
        }

        const ext = await currentViewer.loadExtension('Autodesk.DocumentBrowser', {});
        ext?.activate('');
      } else {
        const ext = await currentViewer.getExtension('Autodesk.DocumentBrowser');
        if (ext) {
          ext.unload();
        }
      }
    }

    if (oasisViewer.current?.viewer) {
      loadExtension(oasisViewer.current?.viewer);
    }
  }, [oasisViewer, enableDocumentBrowser]);

  useEffect(
    () => {
      if (!resourcesLoaded || !ref.current || modelLoaded) {
        return;
      }
      class OasisViewer extends Autodesk.Viewing.AggregatedView {
        public viewer: Autodesk.Viewing.GuiViewer3D;

        constructor(
          viewer: Autodesk.Viewing.GuiViewer3D,
          readonly options: any
        ) {
          super();

          this.viewer = viewer;
        }
      }

      if (!oasisViewer.current) {
        const currentViewer = new Autodesk.Viewing.GuiViewer3D(ref.current);
        oasisViewer.current = new OasisViewer(currentViewer, {});
        currentViewer.setTheme('light-theme');

        // currentViewer.loadExtension('Autodesk.ModelStructure', {}).then(ext => {
        //   if (!currentViewer) {
        //     return;
        //   }
        //   const newViewerModelStructurePanel = new Autodesk.Viewing.Extensions.ViewerModelStructurePanel(
        //     currentViewer,
        //     // @ts-ignore
        //     // @TODO Viewer provide inconsistent types for the model structure panel.
        //     'ModelBrowser',
        //     {
        //       // This will prevent hiding search bar on console.
        //       hideSearch: false,
        //       // This will disable isolation when clicking on a node.
        //       // These caused inconsistency between the the model structure for current user and collaborators.
        //       // For now we toggle visibility when clicking on a node.
        //       docStructureConfig: { click: { onObject: ['selectOnly'] } },
        //     }
        //   );
        //   const activate = () => {
        //     currentViewer.setModelStructurePanel(newViewerModelStructurePanel);
        //     newViewerModelStructurePanel.setVisible(true);
        //     ext.activate('');
        //   };
        //   // if geometry is already loaded, activate the panel
        //   if (currentViewer.model) {
        //     console.log('Geometry already loaded: activate model structure panel');
        //     activate();
        //   } else {
        //     currentViewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
        //       console.log('OBJECT_TREE_CREATED_EVENT: activate model structure panel');
        //       activate();
        //     });
        //   }
        // });
        currentViewer.start();
        currentViewer.setQualityLevel(false, false);
      }

      function waitForPropertyDb() {
        const currentViewer = oasisViewer.current?.viewer;
        if (!currentViewer) {
          throw new Error('Viewer object undefined');
        }
        if ((currentViewer.model.getPropertyDb() as any).isLoadDone()) {
          Oasis.Logger.info('Property DB Load completed.');
          updateModelLoaded(true);
          currentViewer.loadExtension('Autodesk.AEC.LevelsExtension', {}).then(ext => {
            levelsExt.current = ext as Autodesk.AEC.LevelsExtension;
          });
          if (listenerAdded.current) {
            currentViewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
            listenerAdded.current = false;
          }
        } else {
          if (!listenerAdded.current) {
            Oasis.Logger.info('Waiting for property DB to load.');
            currentViewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
            listenerAdded.current = true;
          }
        }
      }

      async function onInitialized() {
        const currentViewer = oasisViewer.current?.viewer;
        let urn = `urn:${btoa(versionId)}`;
        urn = urn.replace('/', '_');

        // for (const event of [
        //   Autodesk.Viewing.AGGREGATE_FIT_TO_VIEW_EVENT,
        //   Autodesk.Viewing.AGGREGATE_ISOLATION_CHANGED_EVENT,
        //   Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
        //   Autodesk.Viewing.ANIM_ENDED,
        //   Autodesk.Viewing.ANIMATION_READY_EVENT,
        //   Autodesk.Viewing.BEFORE_MODEL_UNLOAD_EVENT,
        //   Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        //   Autodesk.Viewing.CAMERA_TRANSITION_COMPLETED,
        //   Autodesk.Viewing.CUTPLANES_CHANGE_EVENT,
        //   Autodesk.Viewing.CANCEL_LEAFLET_SCREENSHOT,
        //   Autodesk.Viewing.ESCAPE_EVENT,
        //   Autodesk.Viewing.EXPLODE_CHANGE_EVENT,
        //   // Autodesk.Viewing.EXTENSION_ACTIVATED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_DEACTIVATED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_LOADED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_PRE_ACTIVATED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_PRE_DEACTIVATED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_PRE_LOADED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_PRE_UNLOADED_EVENT,
        //   // Autodesk.Viewing.EXTENSION_UNLOADED_EVENT,
        //   // Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
        //   Autodesk.Viewing.FIT_TO_VIEW_EVENT,
        //   Autodesk.Viewing.FRAGMENTS_LOADED_EVENT,
        //   Autodesk.Viewing.FULLSCREEN_MODE_EVENT,
        //   Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        //   Autodesk.Viewing.GEOMETRY_DOWNLOAD_COMPLETE_EVENT,
        //   Autodesk.Viewing.HIDE_EVENT,
        //   Autodesk.Viewing.HIDE_ALL_EVENT,
        //   Autodesk.Viewing.HYPERLINK_EVENT,
        //   Autodesk.Viewing.ISOLATE_EVENT,
        //   Autodesk.Viewing.LAYER_VISIBILITY_CHANGED_EVENT,
        //   Autodesk.Viewing.LOAD_GEOMETRY_EVENT,
        //   Autodesk.Viewing.LOAD_MISSING_GEOMETRY,
        //   Autodesk.Viewing.MODEL_ADDED_EVENT,
        //   Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
        //   Autodesk.Viewing.MODEL_REMOVED_EVENT,
        //   Autodesk.Viewing.MODEL_LAYERS_LOADED_EVENT,
        //   Autodesk.Viewing.MODEL_UNLOADED_EVENT,
        //   Autodesk.Viewing.MODEL_VIEWPORT_BOUNDS_CHANGED_EVENT,
        //   // Autodesk.Viewing.NAVIGATION_MODE_CHANGED_EVENT,
        //   Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
        //   Autodesk.Viewing.OBJECT_TREE_UNAVAILABLE_EVENT,
        //   // Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED,
        //   Autodesk.Viewing.PREF_CHANGED_EVENT,
        //   Autodesk.Viewing.PREF_RESET_EVENT,
        //   // Autodesk.Viewing.PROGRESS_UPDATE_EVENT,
        //   // Autodesk.Viewing.RENDER_FIRST_PIXEL,
        //   Autodesk.Viewing.RENDER_OPTION_CHANGED_EVENT,
        //   // Autodesk.Viewing.RENDER_PRESENTED_EVENT,
        //   Autodesk.Viewing.RESET_EVENT,
        //   Autodesk.Viewing.RESTORE_DEFAULT_SETTINGS_EVENT,
        //   Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        //   // Autodesk.Viewing.SETTINGS_PANEL_CREATED_EVENT,
        //   Autodesk.Viewing.SHOW_EVENT,
        //   Autodesk.Viewing.SHOW_ALL_EVENT,
        //   Autodesk.Viewing.SHOW_PROPERTIES_EVENT,
        //   Autodesk.Viewing.TEXTURES_LOADED_EVENT,
        //   // Autodesk.Viewing.TOOL_CHANGE_EVENT,
        //   // Autodesk.Viewing.TOOLBAR_CREATED_EVENT,
        //   // Autodesk.Viewing.TRANSITION_STARTED,
        //   // Autodesk.Viewing.TRANSITION_ENDED,
        //   // Autodesk.Viewing.VIEWER_INITIALIZED,
        //   // Autodesk.Viewing.VIEWER_RESIZE_EVENT,
        //   Autodesk.Viewing.VIEWER_STATE_RESTORED_EVENT,
        //   // Autodesk.Viewing.VIEWER_UNINITIALIZED,
        //   // Autodesk.Viewing.WEBGL_CONTEXT_LOST_EVENT,
        // ]) {
        //   currentViewer?.addEventListener(event, console.log);
        // }

        Autodesk.Viewing.Document.load(
          urn,
          async doc => {
            if (!currentViewer) {
              Oasis.Logger.error({ doc, urn, msg: '<LargeModelViewer /> LMV failed to initialize.' });
              return null;
            }

            const originalTick = currentViewer.impl.tick;
            if (throttleLMV) {
              // @ts-ignore
              currentViewer.impl.setFPSTargets(0, 1000, 1000);
              // Render rarely until we have loaded the geometry
              currentViewer.impl.tick = throttle(originalTick, 1000);
            }
            const root = doc.getRoot();
            const model = root.getDefaultGeometry();

            if (noGeometryLoading) {
              currentViewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, e => {
                const toolbar = e.target.toolbar;
                // Hide the toolbar entirely
                toolbar.setVisible(false);
              });

              // @ts-ignore
              currentViewer.addEventListener(Autodesk.Viewing.VIEW_CUBE_CREATED_EVENT, () => {
                // @ts-ignore
                const viewCubeWrapper = document.getElementsByClassName('viewcubeWrapper');
                if (viewCubeWrapper.length > 0 && viewCubeWrapper[0] && (viewCubeWrapper[0] as HTMLElement).style) {
                  (viewCubeWrapper[0] as HTMLElement).style.display = 'none';
                }
              });
            }

            // reduce throttling to render more often after geometry has loaded
            currentViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
              if (throttleLMV) {
                // @ts-ignore
                currentViewer.impl.setFPSTargets(0, 10, 10);
                // @ts-ignore
                currentViewer.impl.tick = throttle(originalTick, 100);
              }
            });

            try {
              await currentViewer.loadDocumentNode(doc, model, { skipMeshLoad: noGeometryLoading });
              waitForPropertyDb();
              setBubbleNode(_findInitialBubble(root));

              Oasis.Logger.info({ msg: 'Successfully loaded' });
            } catch (error) {
              Oasis.Logger.error({
                msg: '<LargeModelViewer /> Failed to load document.',
                error,
              });
            }
          },
          errorCode => {
            Oasis.Logger.error({ msg: '<LargeModelViewer /> Error loading document: ', errorCode });
          },
          null
        );
      }
      const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');

      Autodesk.Viewing.Initializer(
        {
          env: Oasis.Env.store.lmvEnv, // 'AutodeskStating2' || 'AutodeskProduction2',
          api: 'streamingV2',
          language: 'en',
          getAccessToken: async cb => {
            const result = await Oasis.TokenManager.getAccessToken();

            if (result.ok) {
              cb?.(result.value, Oasis.TokenManager.getExpiration());
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to verify session.',
              });
            }
          },
          refreshToken: async onRefresh => {
            const result = await Oasis.TokenManager.refresh();

            if (result.ok) {
              onRefresh?.(result.value.oxygenToken, Oasis.TokenManager.getExpiration());
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to refresh session.',
              });
            }
          },
          optOutTrackingByDefault: true,
          useADP: false,
          lmvResourceRoot: resourceBase,
        },
        onInitialized
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourcesLoaded, modelLoaded, versionId]
  );

  useEffect(() => {
    return () => {
      if (oasisViewer.current) {
        const currentViewer = oasisViewer.current.viewer;
        Oasis.Logger.info('<LargeModelViewer /> Shutting down viewer.');
        currentViewer.finish();
        Autodesk.Viewing.shutdown();
        oasisViewer.current = undefined;
      }
    };
  }, []);

  const projectsApiConfiguration = useMemo(
    () =>
      getProjectsConfiguration(
        {
          apigeeBackend: getApigeeUrl(),
          nucleusAggregatesPath: `/construction/aggregate-staging`,
          region: 'US',
        },
        {
          getToken: async () => {
            const result = await Oasis.TokenManager.getAccessToken();

            if (result.ok) {
              return result.value!;
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to verify session.',
              });
              return '';
            }
          },
        }
      ),
    []
  );

  const onClose = () => {
    setActiveTool(null);
  };

  return (
    <div className="flex items-stretch border-t border-charcoal-100 h-full">
      <nav className="w-[3.25rem] h-full bg-white border-r border-charcoal-100">
        <ul>
          <li>
            {[
              { name: 'model-browser', icon: <ModelBrowserIcon />, disabled: false },
              { name: 'view-points', icon: <BookmarkIcon />, disabled: !hasViewPoints },
              { name: 'saved-views', icon: <BookmarkIcon />, disabled: !hasViewPoints },
              { name: 'levels', icon: <LevelsIcon />, disabled: false },
              { name: 'sheets', icon: <SheetsIcon />, disabled: false },
            ].map(tool => (
              <button
                key={tool.name}
                onClick={() => setActiveTool(tool.name)}
                // disabled={tool.disabled}
                className={clsx(
                  'flex items-center justify-center w-full h-12',
                  tool.disabled ? 'opacity-20' : activeTool === tool.name ? 'text-blue-500' : 'hover:bg-charcoal-50'
                )}
              >
                {tool.icon}
              </button>
            ))}
          </li>
        </ul>
      </nav>

      <div className="relative flex-1 overflow-hidden -z-0">
        {modelLoaded && view && viewer && (
          <AlloyProvider projectId={projectId.split('.')[1]} locale="en">
            {/* @ts-ignore */}
            <ViewerContainerProvider viewer={viewer} projectsApiConfiguration={projectsApiConfiguration}>
              <ViewpointProvider viewer={viewer}>
                <ViewerModelPropertyFilteringFacadeProvider view={view} composites={false}>
                  <FilterStateProvider view={view}>
                    <ActiveFilterContentProvider view={view}>
                      <ModelBrowserBridge
                        viewer={viewer}
                        open={activeTool === 'model-browser'}
                        onClose={onClose}
                        // isModelFilterEnabled={isModelFilteringEnabled(
                        //   projectUidOnMount.current
                        // )}
                        // isFilterCustomizationEnabled={false}
                        // isModelBrowserAlloy={true}
                        // isAdvancedFilterEnabled={true}
                        // viewAecExchangeQuery={viewAecExchangeQuery}
                      />

                      <ViewpointsBridge
                        viewer={viewer}
                        aggregatedView={view}
                        open={activeTool === 'view-points'}
                        onClose={onClose}
                        excludeMasterViews={false}
                        bubbleNode={bubbleNode}
                        selectedGuid={bubbleNode?.guid()}
                        onViewpointsParsed={setHasViewpoints}
                        isBeta={false}
                        isNwd
                      />

                      <SavedViewsBridge
                        viewer={viewer}
                        open={activeTool === 'saved-views'}
                        onClose={onClose}
                        bubbleNode={bubbleNode}
                        currentGuid={bubbleNode?.guid()}
                        onViewpointsParsed={setHasViewpoints}
                      />

                      <SheetsPanelBridge
                        viewer={viewer}
                        open={activeTool === 'sheets'}
                        onClose={onClose}
                        bubbleNode={bubbleNode}
                        // openSelectedSheet={goToViewable}
                        // canAddSheetToModel={
                        //   isHypermodelEnabled(projectUidOnMount.current) &&
                        //   (props.permission?.isAdmin ||
                        //     !!props.permission?.permissionActions.find(p => p.toUpperCase() === 'EDIT'))
                        // }
                        // notificationCenter={notificationCenter.current}
                        // enableFDX={enableFDX}
                        // onShowCdeValidator={props.onShowCdeValidator}
                        // onOpenFolder={props.onOpenFolder}
                        // viewable={viewable}
                        // enableExchangeOnIFC={!!props.featureFlags?.enableExchangeOnIFC}
                      />

                      {levelsExt.current && (
                        <LevelsPanelBridge
                          levelsExtension={levelsExt.current}
                          open={activeTool === 'levels'}
                          onClose={onClose}
                        />
                      )}
                    </ActiveFilterContentProvider>
                  </FilterStateProvider>
                </ViewerModelPropertyFilteringFacadeProvider>
              </ViewpointProvider>
            </ViewerContainerProvider>
          </AlloyProvider>
        )}
        <div
          ref={ref}
          id="test-viewer"
          className={`${clsx('relative w-full h-full overflow-hidden -z-0', $collab.status === 'LOADING' || (connectionStatus === 'CONNECTING' && 'is-loading'))}`}
        >
          {$env.isVr ? (
            $collab.status !== 'FAILED' && connectionStatus !== 'ERROR' ? (
              <>
                <div></div>
                <div
                  className={`w-full h-full items-center justify-center flex-col flex ${$collab.status !== 'LOADED' || connectionStatus !== 'CONNECTED' ? 'visible' : 'hidden'}`}
                >
                  <>
                    <ProgressRing size="large" />
                    <p className="text-body-lg mt-5">Syncing. This may take few seconds...</p>
                    <div />
                  </>
                </div>
              </>
            ) : (
              <div className="absolute bg-white inset-0 z-10 flex items-center justify-center">
                <FluidErrorState />
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

function _useLoadResources(throttleLMV: boolean, noGeometryLoading: boolean) {
  const [styleLoaded, setStyleLoaded] = useState(Boolean(window.Autodesk));
  const [scriptLoaded, setScriptLoaded] = useState(Boolean(window.Autodesk));
  const $env = Oasis.Env.useStore();

  useEffect(
    () => {
      if (!window.Autodesk) {
        const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');
        const style = document.createElement('link');
        if ($env.isVrWorkshop || $env.isVrHomespace) {
          if (noGeometryLoading) {
            // style.href = '/css/viewerstyle.min.NOGEOMETRY.css';
          } else {
            style.href = '/css/viewerstyle.min.css';
          }
        } else {
          style.href = `${resourceBase}/style.min.css`;
        }
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.onload = () => setStyleLoaded(true);

        const script = document.createElement('script');

        if (noGeometryLoading) {
          script.src = '/js/viewer3D.min.NOGEOMETRY.js';
        } else {
          script.src = `${resourceBase}/viewer3D.min.js`;
        }
        script.async = true;
        script.onload = () => setScriptLoaded(true);

        document.body.appendChild(style);
        document.body.appendChild(script);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return styleLoaded && scriptLoaded;
}

function _findInitialBubble(bubble: Autodesk.Viewing.BubbleNode) {
  for (const child of bubble.children) {
    if (child.data.name === 'Sheets') {
      return child.children[0] || child;
    }

    return _findInitialBubble(child);
  }

  return bubble;
}
