import { Oasis } from '@oasis/sdk';
import { NotificationManager } from '~/shared/components/base/notification-manager';

// Looks like we arent the only ones that run into auth/config issues.
// https://git.autodesk.com/BIM360/bim360-navisworks-issues-ui/blob/e1ac74faf9edb2abc42e2051d085cebd56dd5537/src/helpers/configureAcc.ts
export function configureAcc() {
  window['REACT_APP_ACS_ENV'] = 'PRODUCTION';
  window['REACT_APP_ACS_REGION'] = 'US';
  window['__ACSGetAccessTokenVersion'] = 1;
  window['__ACSGetAccessToken'] = async (): Promise<any> => {
    const result = await Oasis.TokenManager.getAccessToken();

    if (result.ok) {
      return {
        accessToken: result.value,
        expiresAt: (Date.now() + 30 * 1000).toString(),
      };
    } else {
      NotificationManager.push({
        status: 'error',
        content: 'Failed to verify session.',
      });
      throw new Error('Failed to verify session.');
    }
  };
}
