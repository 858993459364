import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useProxy } from 'valtio/utils';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { CollaborationExtensionState, useCollaborationExtension } from '~/shared/hooks/use-collaboration-extension';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { FluidErrorState } from './components/fluid-error-state';

interface Props {
  versionId: string;
}

export function LargeModelViewer({ versionId }: Props) {
  const $env = Oasis.Env.useStore();
  const $collab = useProxy(CollaborationExtensionState);
  const [enableDocumentBrowser, throttleLMVFlag, noGeometryLoadingFlag, isCameraSync] = useFeatureFlags([
    '230711-3102-enable-lmv-document-browser',
    '230926-3543-throttle-lmv',
    '231002-4151-no-geometry-loading-in-lmv',
    '241015-7221-unreal-lmv-cameras-sync',
  ]);
  const noGeometryLoading = noGeometryLoadingFlag && $env.isVr && !isCameraSync;
  const throttleLMV = throttleLMVFlag && $env.isVr && !isCameraSync;

  const ref = useRef<HTMLDivElement>(null);
  const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();
  const listenerAdded = useRef(false);

  const resourcesLoaded = _useLoadResources(!!throttleLMV, !!noGeometryLoading);
  const [modelLoaded, updateModelLoaded] = useState(false);

  const { connectionStatus } = useCollaborationExtension({
    viewer: viewer.current,
    versionId,
    modelLoaded,
  });

  useEffect(() => {
    async function loadExtension(currentViewer: Autodesk.Viewing.GuiViewer3D) {
      if (enableDocumentBrowser) {
        const existing = await currentViewer.getExtension('Autodesk.DocumentBrowser');
        if (existing) {
          // we cannot reactivate DocumentBrowser if it had already been loaded to we need to reload the page
          window.location.reload();
        }

        const ext = await currentViewer.loadExtension('Autodesk.DocumentBrowser', {});
        ext?.activate('');
      } else {
        const ext = await currentViewer.getExtension('Autodesk.DocumentBrowser');
        if (ext) {
          ext.unload();
        }
      }
    }

    if (viewer.current) {
      loadExtension(viewer.current);
    }
  }, [viewer, enableDocumentBrowser]);

  useEffect(
    () => {
      if (!resourcesLoaded || !ref.current || modelLoaded) {
        return;
      }

      if (!viewer.current) {
        viewer.current = new Autodesk.Viewing.GuiViewer3D(ref.current);
        viewer.current.setTheme('light-theme');

        viewer.current.loadExtension('Autodesk.ModelStructure', {}).then(ext => {
          if (!viewer.current) {
            return;
          }
          const newViewerModelStructurePanel = new Autodesk.Viewing.Extensions.ViewerModelStructurePanel(
            viewer.current,
            // @ts-ignore
            // @TODO Viewer provide inconsistent types for the model structure panel.
            'ModelBrowser',
            {
              // This will prevent hiding search bar on console.
              hideSearch: false,
              // This will disable isolation when clicking on a node.
              // These caused inconsistency between the the model structure for current user and collaborators.
              // For now we toggle visibility when clicking on a node.
              docStructureConfig: { click: { onObject: ['selectOnly'] } },
            }
          );
          const activate = () => {
            viewer.current?.setModelStructurePanel(newViewerModelStructurePanel);
            newViewerModelStructurePanel.setVisible(true);
            ext.activate('');
          };
          // if geometry is already loaded, activate the panel
          if (viewer.current.model) {
            console.log('Geometry already loaded: activate model structure panel');
            activate();
          } else {
            viewer.current.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
              console.log('OBJECT_TREE_CREATED_EVENT: activate model structure panel');
              activate();
            });
          }
        });
        viewer.current.start();
        viewer.current.setQualityLevel(false, false);
      }

      function waitForPropertyDb() {
        if (!viewer.current) {
          throw new Error('Viewer object undefined');
        }
        if ((viewer.current.model.getPropertyDb() as any).isLoadDone()) {
          Oasis.Logger.info('Property DB Load completed.');
          updateModelLoaded(true);
          if (listenerAdded.current) {
            viewer.current.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
            listenerAdded.current = false;
          }
        } else {
          if (!listenerAdded.current) {
            Oasis.Logger.info('Waiting for property DB to load.');
            viewer.current.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
            listenerAdded.current = true;
          }
        }
      }

      async function onInitialized() {
        let urn = `urn:${btoa(versionId)}`;
        urn = urn.replace('/', '_');

        Autodesk.Viewing.Document.load(
          urn,
          async doc => {
            if (!viewer.current) {
              Oasis.Logger.error({ doc, urn, msg: '<LargeModelViewer /> LMV failed to initialize.' });
              return null;
            }

            const originalTick = viewer.current.impl.tick;
            if (throttleLMV) {
              // @ts-ignore
              viewer.current.impl.setFPSTargets(0, 1000, 1000);
              // Render rarely until we have loaded the geometry
              viewer.current.impl.tick = throttle(originalTick, 1000);
            }
            const root = doc.getRoot();
            const model = root.getDefaultGeometry();

            if (noGeometryLoading) {
              viewer.current.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, e => {
                const toolbar = e.target.toolbar;
                // Hide the toolbar entirely
                toolbar.setVisible(false);
              });

              // @ts-ignore
              viewer.current.addEventListener(Autodesk.Viewing.VIEW_CUBE_CREATED_EVENT, () => {
                // @ts-ignore
                const viewCubeWrapper = document.getElementsByClassName('viewcubeWrapper');
                if (viewCubeWrapper.length > 0 && viewCubeWrapper[0] && (viewCubeWrapper[0] as HTMLElement).style) {
                  (viewCubeWrapper[0] as HTMLElement).style.display = 'none';
                }
              });
            }

            // reduce throttling to render more often after geometry has loaded
            viewer.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
              if (throttleLMV) {
                // @ts-ignore
                viewer.current.impl.setFPSTargets(0, 10, 10);
                // @ts-ignore
                viewer.current.impl.tick = throttle(originalTick, 100);
              }
            });

            try {
              await viewer.current.loadDocumentNode(doc, model, { skipMeshLoad: noGeometryLoading });
              waitForPropertyDb();
              Oasis.Logger.info({ msg: 'Successfully loaded' });
            } catch (error) {
              Oasis.Logger.error({
                msg: '<LargeModelViewer /> Failed to load document.',
                error,
              });
            }
          },
          errorCode => {
            Oasis.Logger.error({ msg: '<LargeModelViewer /> Error loading document: ', errorCode });
          },
          null
        );
      }
      const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');

      Autodesk.Viewing.Initializer(
        {
          env: Oasis.Env.store.lmvEnv, // 'AutodeskStating2' || 'AutodeskProduction2',
          api: 'streamingV2',
          language: 'en',
          getAccessToken: async cb => {
            const result = await Oasis.TokenManager.getAccessToken();

            if (result.ok) {
              cb?.(result.value, Oasis.TokenManager.getExpiration());
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to verify session.',
              });
            }
          },
          refreshToken: async cb => {
            const result = await Oasis.TokenManager.refresh();

            if (result.ok) {
              cb?.(result.value.oxygenToken, Oasis.TokenManager.getExpiration());
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to refresh session.',
              });
            }
          },
          optOutTrackingByDefault: true,
          useADP: false,
          lmvResourceRoot: resourceBase,
        },
        onInitialized
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourcesLoaded, modelLoaded, versionId]
  );

  useEffect(() => {
    return () => {
      if (viewer.current) {
        Oasis.Logger.info('<LargeModelViewer /> Shutting down viewer.');
        viewer.current.finish();
        Autodesk.Viewing.shutdown();
        viewer.current = undefined;
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`${clsx('relative w-full h-full overflow-hidden -z-0', $collab.status === 'LOADING' || (connectionStatus === 'CONNECTING' && 'is-loading'))}`}
    >
      {$env.isVr ? (
        $collab.status !== 'FAILED' && connectionStatus !== 'ERROR' ? (
          <div
            className={`w-full h-screen items-center justify-center flex-col flex ${$collab.status !== 'LOADED' || connectionStatus !== 'CONNECTED' ? 'visible' : 'hidden'}`}
          >
            <>
              <ProgressRing size="large" />
              <p className="text-body-lg mt-5">Syncing. This may take few seconds...</p>
              <div />
            </>
          </div>
        ) : (
          <div className="absolute bg-white inset-0 z-10 flex items-center justify-center">
            <FluidErrorState />
          </div>
        )
      ) : null}
    </div>
  );
}

function _useLoadResources(throttleLMV: boolean, noGeometryLoading: boolean) {
  const [styleLoaded, setStyleLoaded] = useState(Boolean(window.Autodesk));
  const [scriptLoaded, setScriptLoaded] = useState(Boolean(window.Autodesk));
  const $env = Oasis.Env.useStore();

  useEffect(
    () => {
      if (!window.Autodesk) {
        const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');
        const style = document.createElement('link');
        if ($env.isVrWorkshop || $env.isVrHomespace) {
          if (noGeometryLoading) {
            style.href = '/css/viewerstyle.min.NOGEOMETRY.css';
          } else {
            style.href = '/css/viewerstyle.min.css';
          }
        } else {
          style.href = `${resourceBase}/style.min.css`;
        }
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.onload = () => setStyleLoaded(true);

        const script = document.createElement('script');

        if (noGeometryLoading) {
          script.src = '/js/viewer3D.min.NOGEOMETRY.js';
        } else {
          script.src = `${resourceBase}/viewer3D.min.js`;
        }
        script.async = true;
        script.onload = () => setScriptLoaded(true);

        document.body.appendChild(style);
        document.body.appendChild(script);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return styleLoaded && scriptLoaded;
}
