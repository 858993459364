import { DataBinder } from '@fluid-experimental/property-binder';
import { SharedPropertyTree } from '@fluid-experimental/property-dds';
import {
  ArrayProperty,
  BoolMapProperty,
  Float32Property,
  NodeProperty,
  PropertyFactory,
} from '@fluid-experimental/property-properties';
import { assert } from '@fluidframework/common-utils';
// @ts-ignore
import { ToolsBinding } from './ToolsBinding';
// @ts-ignore
import { VisibilityManagerBinding } from './VisibilityManagerBinding';
import { TYPES, PROPERTY_NAMES } from './Constants';
import { ModificationContext } from '@fluid-experimental/property-binder/dist/data_binder/modificationContext';
import _ from 'lodash';
import { ConnectionManager } from '../../main';

const { CONTROLS, EXPLODE, CUTPLANES, TOOLS, VISIBILITY_MANAGER, HIDDEN_NODES, OASIS_HIDDEN_NODES, ISOLATED_NODES } =
  PROPERTY_NAMES;

const Schemas = [
  /*************************** Old schemas *******************************/
  {
    typeid: TYPES.MEASUREMENT_1_0_0,
    properties: [
      { id: 'modelUrn', typeid: 'String' },
      { id: 'position1', typeid: TYPES.POSITION },
      { id: 'position2', typeid: TYPES.POSITION },
    ],
  },
  /*************************** New schemas *******************************/
  {
    typeid: TYPES.POSITION,
    properties: [
      { id: 'x', typeid: 'Float32' },
      { id: 'y', typeid: 'Float32' },
      { id: 'z', typeid: 'Float32' },
    ],
  },
  {
    typeid: TYPES.MEASUREMENT,
    properties: [
      { id: 'createdBy', typeid: 'String' },
      { id: 'position1', typeid: TYPES.POSITION },
      { id: 'position2', typeid: TYPES.POSITION },
    ],
  },
  {
    typeid: TYPES.ANNOTATION,
    properties: [
      // Ordered list of points that define the annotation
      { id: 'points', typeid: TYPES.POSITION, context: 'array' },
      // UserId of the user who created the annotation
      { id: 'createdBy', typeid: 'String' },
      { id: 'color', typeid: 'String' },
    ],
  },
  {
    typeid: TYPES.SESSION,
    properties: [
      { id: 'measurements', typeid: TYPES.MEASUREMENT, context: 'map' },
      { id: 'annotations', typeid: TYPES.ANNOTATION, context: 'map' },
    ],
  },
  {
    typeid: TYPES.CONTROLS,
    properties: [{ id: VISIBILITY_MANAGER, typeid: TYPES.VISIBILITY_MANAGER }],
  },
  {
    typeid: TYPES.TOOLS,
    properties: [
      { id: EXPLODE, typeid: 'Float32' },
      { id: CUTPLANES, context: 'array', typeid: TYPES.VECTOR4 },
    ],
  },
  {
    typeid: TYPES.VECTOR4,
    properties: [
      { id: 'x', typeid: 'Float32' },
      { id: 'y', typeid: 'Float32' },
      { id: 'z', typeid: 'Float32' },
      { id: 'w', typeid: 'Float32' },
    ],
  },
  {
    typeid: TYPES.VISIBILITY_MANAGER,
    properties: [
      { id: HIDDEN_NODES, context: 'map', typeid: 'Bool' },
      { id: OASIS_HIDDEN_NODES, context: 'map', typeid: 'Bool' },
      { id: ISOLATED_NODES, context: 'map', typeid: 'Bool' },
    ],
  },
];

Schemas.forEach(schema => PropertyFactory.register(schema));

type CallbackType = (node: number, visibility: boolean, opType: string) => void;

/**
 * A store for managing the shared state of the app.
 */
export class Store {
  private dataBinder = new DataBinder();
  private throttledCommit: () => void;
  private tree: SharedPropertyTree;
  constructor(
    private readonly connectionManager: ConnectionManager,
    private readonly nodesList = []
  ) {
    this.tree = connectionManager.propertyTree;
    this.throttledCommit = _.throttle(() => {
      this.tree.commit();
    }, 3000);
  }

  private setAllVisible() {
    this.nodesList.forEach(node => {
      // with hidden=false
      this.insertNode(node, false, this.oasisHiddenNodes);
    });
  }

  private get controls(): NodeProperty {
    const controls = this.tree.root.get<NodeProperty>(CONTROLS);
    assert(controls !== undefined, `can't find the property ${CONTROLS}`);
    return controls;
  }

  private get tools(): NodeProperty {
    const tools = this.controls.get<NodeProperty>(TOOLS);
    assert(tools !== undefined, `can't find the property ${TOOLS}`);
    return tools;
  }

  private get isolatedNodes(): BoolMapProperty {
    const isolatedNodes = this.visibilityManager.get<BoolMapProperty>(ISOLATED_NODES);
    assert(
      isolatedNodes !== undefined,
      `can't find the property the property ${ISOLATED_NODES} in ${VISIBILITY_MANAGER}`
    );
    return isolatedNodes;
  }

  private get oasisHiddenNodes(): BoolMapProperty {
    const oasisIsolatedNodes = this.visibilityManager.get<BoolMapProperty>(OASIS_HIDDEN_NODES);
    assert(
      oasisIsolatedNodes !== undefined,
      `can't find the property the property ${OASIS_HIDDEN_NODES} in ${VISIBILITY_MANAGER}`
    );
    return oasisIsolatedNodes;
  }

  private get hiddenNodes(): BoolMapProperty {
    const hiddenNodes = this.visibilityManager.get<BoolMapProperty>(HIDDEN_NODES);
    assert(hiddenNodes !== undefined, `can't find the property the property ${HIDDEN_NODES} in ${VISIBILITY_MANAGER}`);
    return hiddenNodes;
  }

  private get visibilityManager(): NodeProperty {
    let visibilityManager = this.controls.get<NodeProperty>(VISIBILITY_MANAGER);
    if (!visibilityManager) {
      this.initialize();
      visibilityManager = this.controls.get<NodeProperty>(VISIBILITY_MANAGER)!;
    }
    return visibilityManager;
  }

  public initialize(): void {
    // Initialize the document with the controls property
    if (!this.tree.root.has(CONTROLS)) {
      this.tree.root.insert(CONTROLS, PropertyFactory.create(TYPES.CONTROLS));
      // Make sure the document is initialized cross collaborators.
      this.commit();
    }

    // Start listening to changes
    this.dataBinder.attachTo(this.tree);
  }

  public destroy(): void {
    this.dataBinder.detach(false);
  }

  public commit() {
    this.tree.commit();
  }

  public pushNotificationDelayScope() {
    this.tree.pushNotificationDelayScope();
  }

  public popNotificationDelayScope() {
    this.tree.popNotificationDelayScope();
  }

  public hideAll(): void {
    this.hiddenNodes.clear();
    this.isolatedNodes.clear();
  }

  public reset(): void {
    this.tree.pushNotificationDelayScope();
    if (this.tree.root.has(CONTROLS)) {
      this.tree.root.remove(CONTROLS);
      this.commit();
    }

    this.initialize();
    this.tree.popNotificationDelayScope();
  }

  private removeNode(node: string): void {
    const exist = this.hiddenNodes.has(node);
    assert(exist !== undefined, `trying to hide the node ${node} which doesn't exist.`);
    this.hiddenNodes.set(node, false);
  }

  private insertNode(node: string, isHidden = true, nodeProp = this.hiddenNodes): void {
    if (nodeProp.has(node)) {
      nodeProp.set(node, isHidden);
    } else {
      nodeProp.insert(node, isHidden);
    }
  }

  public setNodesVisibility(visibility: Record<string, boolean>): void {
    this.dataBinder.pushBindingActivationScope();
    this.oasisHiddenNodes.setValues(visibility);
    this.dataBinder.popBindingActivationScope();
  }

  public setNodeVisibility(node: number, isHidden: boolean): void {
    this.dataBinder.pushBindingActivationScope();
    if (isHidden) {
      this.removeNode(JSON.stringify(node));
    } else {
      this.insertNode(JSON.stringify(node));
    }
    this.dataBinder.popBindingActivationScope();
  }

  public isolate(node: number): void {
    const key = JSON.stringify(node);
    this.tree.pushNotificationDelayScope();
    this.setAllVisible();
    this.hiddenNodes.clear();
    this.isolatedNodes.clear();
    this.tree.popNotificationDelayScope();
    this.tree.commit();
    this.isolatedNodes.set(key, true);
    this.tree.commit();
  }

  public isolateNone(): void {
    this.isolatedNodes.clear();
    this.tree.commit();
  }

  private get explodeNode(): Float32Property {
    const explodeNode = this.tools.get<Float32Property>(EXPLODE);
    assert(explodeNode !== undefined, "trying to get explode node, which doesn't exist.");
    return explodeNode;
  }

  private get cutplanesNode(): ArrayProperty {
    const cutplanesNode = this.tools.get<ArrayProperty>(CUTPLANES);
    assert(cutplanesNode !== undefined, "trying to get cutplanes node, which doesn't exist.");
    return cutplanesNode;
  }

  public getExplodeValue(): number {
    return this.explodeNode.getValue();
  }

  public setExplodeValue(scale: number): void {
    if (scale !== undefined) {
      if (this.explodeNode) {
        this.explodeNode.setValue(scale);
      }
    }
    this.commit();
  }

  public getCutPlanes(): THREE.Vector4[] {
    return (this.cutplanesNode.getValues() as any[]).map(
      plane => new THREE.Vector4(plane.x, plane.y, plane.z, plane.w)
    );
  }

  public setCutPlanes(planes: any[]): void {
    const { cutplanesNode } = this;
    if (cutplanesNode) {
      this.tree.pushNotificationDelayScope();
      if (cutplanesNode.getLength() > 0) cutplanesNode.removeRange(0, cutplanesNode.getLength());
      if (planes.length > 0) this.cutplanesNode.insertRange(0, planes);
      this.tree.popNotificationDelayScope();
    }
    this.commit();
  }

  public defineAndActivateVisibilityManagerBinding(
    onHiddenNodesChanged: CallbackType,
    onIsolatedNodesChanged: CallbackType,
    onShowAll = () => { }
  ): void {
    this.dataBinder.defineDataBinding('model', TYPES.VISIBILITY_MANAGER, VisibilityManagerBinding);
    this.dataBinder.activateDataBinding('model', TYPES.VISIBILITY_MANAGER, {
      userData: {
        onHiddenNodesChanged,
        onIsolatedNodesChanged,
      },
    });
    this.dataBinder.registerOnPath(`${CONTROLS}`, ['remove'], (modificationContext: ModificationContext) => {
      if (!modificationContext.isSimulated()) {
        onShowAll();
      }
    });
  }

  public defineAndActivateToolsBinding(userData: any): void {
    this.dataBinder.defineDataBinding('view', TYPES.TOOLS, ToolsBinding);
    this.dataBinder.activateDataBinding('view', TYPES.TOOLS, { userData });
  }

  public signal(topic: string, data: any): void {
    this.connectionManager.submitSignal(topic, data);
  }
}
